.tableSection {
    background-color: var(--gray-00);
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    break-inside: avoid;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1;
    box-shadow: var(--shadow-8);

    font-size: 1em;
    padding: 1.5em;

    @media print {
        display: block;
    }

    @media screen and (max-width: 770px) {
        border-width: 1px 0;
        box-shadow: none;
        padding: 8px;
    }
}

.table {
    width: 100%;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.5em;
    color: var(--gray-90);
    border-collapse: collapse;

    th,
    td {
        vertical-align: middle;
        &:not(:first-child) {
            padding: 0 0.5em;
        }
        &:not(:last-child) {
            padding: 0 0.5em;
        }

        @media screen and (max-width: 770px) {
            &:not(:first-child) {
                padding: 0.2;
            }
            &:not(:last-child) {
                padding: 0.2;
            }
        }
    }
    thead {
        tr {
            height: 39px;
            th {
                font-family: Work Sans;
                font-style: normal;
                font-weight: 500;
                font-size: 0.8em;
                line-height: 150%;
                letter-spacing: div(0.25em, 12.8);
                color: var(--gray-70);
                vertical-align: middle;
            }
        }
    }
    tbody {
        tr {
            height: 3.44em;
        }
        th {
            font-family: Work Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1em;
            line-height: 150%;
        }
    }
    a {
        font-weight: 500;
        @media screen {
            &:hover {
                text-decoration-line: underline;
            }
        }
        font-size: 1em;
        @media screen {
            color: var(--blue-50);
        }
    }
}
