.controlsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 28px;

    @media only screen and (max-width: 770px) {
        flex-direction: column;
    }
}

.subtext {
    margin-left: 12px;
    font-size: 12px;
    color: var(--gray-60);
    width: 100%;
    text-align: right;
    position: relative;
    top: 41px;

    @media only screen and (max-width: 770px) {
        position: static;
        margin-top: 8px;
        text-align: left;
    }
}

.controls {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;

    > button {
        &:not(:last-child) {
            margin-right: 16px;
        }
        padding: 6px 12px;
        height: 36px;
        background-color: var(--gray-00);
        color: var(--gray-90);
        border: none;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08),
            0px 1px 4px rgba(0, 0, 0, 0.3);
        border-radius: 6px;

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08),
                0px 1px 4px rgba(0, 0, 0, 0.5);
        }

        &.isActive {
            background-color: var(--gray-90);
            color: var(--gray-00);
        }
    }
}

.chartWrapperOuter {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: 32px;

    @media only screen and (min-width: 770px) {
        top: 16px;
    }
}

.chartWrapperInner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
