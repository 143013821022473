.linearUnitSelector {
    display: flex;
}

.label {
    margin-right: 1ch;
}

.selectorSpan {
    cursor: pointer;
    display: flex;
}

.iconSpan {
    position: relative;
    top: 5px;
}
