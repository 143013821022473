.header {
    width: 100%;
    color: var(--gray-90);
    padding: 20px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 30px;
    display: block;
    z-index: 1;

    @media only screen and (max-width: 770px) {
        height: 24px;
    }
}
