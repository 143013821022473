@keyframes spinner {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(2880deg);
        transform: rotate(2160deg);
    }
}

@keyframes border {
    0% {
        border-width: 0;
        border-top-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0);
    }
    25% {
        border-top-color: currentColor;
        border-bottom-color: currentColor;
    }
    50% {
        border-width: 0.2em;
    }
    75% {
        border-top-color: currentColor;
        border-bottom-color: currentColor;
    }
    0% {
        border-width: 0;
        border-top-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0);
    }
}

.spinner {
    display: block;
    float: left;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    &.loadingSpinnerActive {
        animation: spinner 3s ease-in-out infinite;
        &:after {
            animation: border 3s ease-in-out infinite;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        border: 0 solid transparent;
    }
}

.loading {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 500ms;
    pointer-events: none;
    border-radius: inherit;
    background-color: transparent;
    z-index: 10;
    &.show {
        opacity: 1;
        pointer-events: auto;
    }
    &.background {
        background-color: #e5e5e5;
    }
    &.blue {
        background-color: var(--blue-50);
    }
    &.overTable {
        background-color: fade-out(#fff, 0.3);
        color: var(--gray-90);
    }
    @media print {
        display: none;
    }
}
