.checkboxWrapper {
    position: relative;
}

.checkbox {
    $checkbox-path: ' + label > :first-child';

    position: absolute;
    opacity: 0;
    width: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    & + label {
        // @include text-body-link;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: var(--gray-90);
        user-select: none;
        position: relative;
    }
    &#{$checkbox-path} {
        transition: 150ms all;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: var(--gray-00);
        border: 1px solid var(--ride-report-inactive-toggle-color);
        font-size: 20px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        svg {
            color: white;
        }
    }
    &:not(:disabled) {
        &:hover,
        &:focus {
            &#{$checkbox-path} {
                box-shadow: 0px 0px 4px var(--ride-report-hover-toggle-color);
            }
            &:not(:checked)#{$checkbox-path} {
                border-color: var(--ride-report-hover-toggle-color);
            }
        }
    }
    &:checked#{$checkbox-path} {
        border-color: transparent;
        background-color: var(--ride-report-active-toggle-color);
    }
    &:disabled#{$checkbox-path} {
        border-color: transparent;
        background-color: var(--gray-30);
    }

    &:not(:checked):disabled#{$checkbox-path} svg {
        color: transparent;
    }
}
