.container {
    max-width: 1200px;
    margin: 32px auto 12px;
    padding: 24px;
    background-color: var(--gray-00);
    border-radius: 6px;

    a {
        font-weight: bold;
        color: var(--blue-50);
        text-decoration: underline;
    }
}

.subheader {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
}
