.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: var(--gray-90);

    background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.85) 100%
        ),
        url(../assets/background-topo.png);
    background-size: 45%;

    @media only screen and (max-width: 960px) {
        background-size: 40%;
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.cta {
    height: 260px;
    width: 720px;
    color: var(--gray-00);
    background-color: var(--green-50);
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    padding: 32px 64px;
    z-index: 10;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1),
        0px -4px 8px rgba(0, 0, 0, 0.12);

    @media only screen and (max-width: 770px) {
        padding: 48px 24px;
        height: 400px;
        width: 100%;
        left: 0;
        transform: none;
    }
}

.ctaButtonContainer {
    margin: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ctaLogo {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    border: 6px solid var(--gray-00);
    height: 80px;
    width: 80px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1),
        0px -2px 8px rgba(0, 0, 0, 0.12);
}

.footer {
    position: relative;
    width: 100%;
    height: auto;
    border-top: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--gray-50);
    background-color: var(--gray-90);
    font-size: 12.8px;
    padding-bottom: 72px;
    margin-top: 120px;
}

.footerLinks {
    padding: 260px 24px 24px 24px;
    font-size: 16px;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 770px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 360px 24px 24px 24px;
    }
}

.footerLink {
    margin: 12px 24px;
    cursor: pointer;
    color: var(--gray-10);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        transition: text-decoration 150ms ease-in-out;
        color: var(--gray-00);
    }
    &:visited {
        color: var(--gray-10);
    }

    position: relative;

    div[data-descr] {
        position: absolute;
        top: -2px;
        right: -12px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: var(--green-30);
        color: var(--gray-90);
    }

    @media only screen and (max-width: 770px) {
        display: none;
    }
}

.title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 39.06px;
    line-height: 55px;
    margin-top: 4px;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
        DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-top: 3rem;
}

.card {
    margin: 1rem;
    flex-basis: 45%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 1em;
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
}

.footerLogoContainer {
    display: none;

    @media only screen and (max-width: 770px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        margin-bottom: 16px;
        color: var(--gray-00);
        font-size: 16px;
        line-height: 150%;
    }
}

.footerLogo {
    height: 100%;
    width: auto;
}

.verticalBar {
    width: 1px;
    height: 100%;
    background-color: var(--gray-30);
    margin: 0 12px;
}

.subheader {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
}
