.namePopCellContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxContainer {
    margin-right: 12px;

    @media only screen and (max-width: 770px) {
        margin-right: 4px;
    }
}

.namePopCellText {
    display: flex;
    flex-direction: column;
    text-align: left;

    .regionName {
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 35px;
        display: flex;
        align-items: center;
        letter-spacing: -0.25px;
    }

    .cityName {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        @media only screen and (max-width: 770px) {
            line-height: 1.3;
        }
    }

    .population {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: var(--gray-60);

        @media only screen and (max-width: 770px) {
            display: none;
        }
    }
}

.noDataTd {
    text-align: center;
    font-weight: normal !important;
    font-size: 16px !important;
    color: var(--gray-60) !important;
}
