.rightSideFilterControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    top: 37px;
    margin-left: 50%;

    @media only screen and (max-width: 770px) {
        justify-content: flex-start;
        top: 0px;
        left: -8px;
        margin-bottom: 16px;
        margin-left: 0;
    }
}

.filterSelectControl {
    max-height: 36px;
    border: 1px solid var(--gray-50);
    border-radius: 6px;
    margin: 0 0 0 16px;
    @media only screen and (max-width: 770px) {
        margin-left: 8px;
    }
}

.filterSelectControl > div {
    position: relative;
    top: -9px;
    max-height: 36px;
}
