.container {
    margin: 64px auto;
    max-width: 1200px;
    background-color: white;

    @media screen and (max-width: 770px) {
        margin: 32px auto 32px;
    }
}

.subheader {
    @media screen and (min-width: 770px) {
        margin-bottom: 0;
    }
}

.scrollableTable {
    width: 100%;
    min-height: 70vh;
    height: calc(90vh - 400px);
    overflow-y: auto;

    @media screen and (max-height: 1000px) {
        min-height: 90vh;
    }
}

.table {
    composes: table from './TableSection.module.scss';

    border-collapse: separate;
    border-spacing: 0 12px;
    -webkit-border-vertical-spacing: 0; // Resolve table scroll bug on Safari (blank space above <th>

    position: relative;
    padding-right: 20px;

    thead {
        background-color: var(--gray-00);
        position: sticky;
        top: 0;
        z-index: 10;
    }

    tbody {
        tr {
            height: 64px;

            text-align: right;
            :first-child {
                text-align: left;
            }

            &.dividerRow {
                height: 1px;

                .dividerCell {
                    border-bottom: 1px dashed var(--gray-50);
                }
            }

            td {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--gray-90);

                @media only screen and (max-width: 770px) {
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            &.bold td {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                letter-spacing: -0.25px;
            }
        }
    }
}

.allCitiesRow {
    @media only screen and (max-width: 770px) {
        display: none;
    }
}

.link {
    color: var(--blue-50);
    word-break: break-all;

    &:hover {
        text-decoration: underline;
    }
}

.ctaContainer {
    margin: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        width: 240px;
        color: var(--gray-00);
        background-color: var(--green-50);
        border: 1px solid transparent;

        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:disabled) {
            &:hover {
                background-color: var(--green-70);
            }
        }
    }
}

.noCitiesRow {
    text-align: center !important;
    font-weight: normal !important;
    font-size: 16px !important;
    color: var(--gray-60) !important;
}

.indexSearchFilterControlContainer {
    margin-bottom: 8px;
    border: none;
}
