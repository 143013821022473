.dropdownContainer {
    position: relative;
}

.dropdown {
    background-color: var(--gray-00);
    color: var(--gray-90);
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    top: calc(100% + 0.5em);
    padding: 0.75em 0;
    width: 100%;
    opacity: 0;
    transition-property: opacity;
    transition: 150ms ease-in-out;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-direction: column;

    .right {
        right: 0;
    }
    .left {
        left: 0;
    }
}

.showDropdown {
    pointer-events: auto;
    opacity: 1;
}

.dropdownButton {
    width: 100%;
    height: 3.5em;
    color: var(--gray-90);
    background: none;
    border: none;
    padding: 0.25em 0.625em;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 150%;
}

.dropdownOuter {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
}

.item {
    cursor: pointer;
    padding: 0.25em 0.75em;
    margin: 1px 0.5em;
    border-radius: 6px;
    color: var(--gray-70);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:hover {
        color: var(--gray-90);
    }
}

.itemActive {
    color: var(--gray-90);
}

.itemWithIcon {
    min-height: 3.5em;
}

.items {
    max-height: 15em;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
}

.icon {
    margin-left: 0.5em;
    margin-right: 0.5em;
    height: 1.25em;
    width: 1.25em;
}

.itemIcon {
    margin-right: 0.5em;
    height: 1.25em;
    width: 1.25em;
}

.iconNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.subtitle {
    background-color: black;
    color: white;

    padding: 2em 4em;
    border-radius: 2em;

    font-size: 0.0625em;
    height: 31em;
    max-width: 100em;

    svg {
        height: 100%;
        width: 100%;
    }
}
