.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-right: 20px;
}

.backgrounded {
    background-color: var(--gray-30);
}

.searchBar {
    align-self: stretch;
    position: relative;
    flex: 1;
    max-width: 250px;
    height: 36px;
}

.rightSideFilterControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.searchBarIcon {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 20px;
    width: 20px;
    fill: var(--gray-70);
}

.searchBarClearButton {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: none;
}

.searchBarClearIcon {
    height: 20px;
    width: 20px;
    fill: var(--gray-70);
}

.searchBarInput {
    width: 100%;
    padding: 8px 8px 8px 48px;
    border: none;
    border-radius: 6px;
    background: none;

    &:focus,
    &:hover {
        color: var(--gray-90);
        font-weight: bold;
        outline: none;
    }

    &:focus + .inputUnderline,
    &:hover + .inputUnderline {
        border-bottom: 1px solid var(--gray-70);
    }
}

.inputUnderline {
    width: 70%;
    margin: auto;
    position: relative;
    top: -5px;
}

.filterBar {
    align-self: stretch;
    border: 1px solid var(--gray-50);
}

.filterButton {
    background: none;
    height: 38px;
    border: 1px solid var(--gray-50);
    border-radius: 6px;
    padding: 6px 16px 6px 16px;
    margin: 0 0 0 8px;
    display: flex;
    flex-direction: row;
}

.closeButton {
    background: white;
    height: 38px;
    border: 1px solid var(--gray-50);
    border-radius: 6px;
    padding: 6px 16px 6px 16px;
    margin: 0 0 0 8px;
    display: flex;
    flex-direction: row;

    span {
        position: relative;
        bottom: 2px;
    }
}

.filterTextSpan {
    position: relative;
    bottom: 2px;
}

.filterBarIcon {
    position: relative;
    top: 3px;
    margin: 0 0 0 6px;
    height: 20px;
    width: 20px;
    fill: var(--gray-70);
}

.displayFilterSettingsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}

.filterSettingDisplay {
    border: 1px solid var(--gray-50);
    border-radius: 6px;
    padding: 1px 6px 1px 8px;
    margin: 0 8px 8px 8px;
    font-size: 16px;
    height: 24px;
}

.filterSettingDisplaySpan {
    position: relative;
    top: -5px;
}

.filterSettingDisplayX {
    height: 20px;
    width: 20px;
    fill: var(--gray-70);
}

.filterSettingDisplayButton {
    height: 20px;
    width: 20px;
    background: none;
    border: none;
    margin-left: 6px;
    padding: 0;
}

.filtersPanel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.filterPanelDropdown {
    background-color: #222a35;
    height: 38px;
    border: 1px solid var(--gray-50);
    border-radius: 6px;
    margin: 0 8px 0 8px;
}

.resetAllFiltersIcon {
    height: 24px;
    width: 24px;
}

.resetAllFiltersButton {
    background: none;
    border: none;
}

.additionalClassForDropdownContainer {
    button {
        color: white;
        padding: 6px 16px 6px 16px;
        align-items: baseline;

        svg {
            position: relative;
            top: 5px;
            margin: 0 0 0 6px;
        }

        span {
            position: relative;
            bottom: 2px;
        }
    }
}

.indexSearchFilterDropdownOpen {
    top: 36px;
    width: auto;
    white-space: nowrap;
    right: 0;

    @media only screen and (max-width: 770px) {
        right: initial;
        left: 0;
    }
}
