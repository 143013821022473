.columnHeader {
    border-bottom: 1px solid var(--gray-30);

    &:not(:first-child) button {
        padding-left: 1em;
    }

    &:first-child button {
        text-align: left;
    }

    button {
        text-align: right;
        border: none;
        font-size: inherit;
        background: none;
        padding: 0;
        position: relative;
        width: 100%;
        svg {
            position: absolute;
            right: -1.25em;
            top: calc(50% - 0.5em);
            font-size: 1.5em;
            display: none;
            color: inherit;
        }
    }

    &:global(.showArrow),
    &:hover {
        button svg {
            display: block;
        }
    }

    &:hover button {
        color: var(--gray-80);
    }
}

.columnHeaderText {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--gray-90);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
        width: 100%;
    }

    :nth-child(2) {
        color: var(--gray-60);
    }
}
