.sectionContainer {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 64px;

    @media only screen and (max-width: 770px) {
        padding: 0;
    }

    > section {
        width: 100%;
        text-align: left;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .headerSection {
        margin: auto;
        margin-top: 96px;
        padding: 0;
        max-width: 1200px;
        border-radius: 24px;
        background-color: var(--gray-00);

        @media only screen and (max-width: 770px) {
            margin-top: 0px;
            border-radius: 12px;
        }

        .headerTitleCard {
            margin: auto;
            max-width: 1200px;
            min-height: 400px;
            padding: 24px;
            border-radius: 24px;
            background: linear-gradient(180deg, #222a35aa 0%, #222a35ee 100%),
                url(../assets/title-block-bg-map.png);
            background-size: cover;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
            color: var(--gray-00);

            display: flex;
            flex-direction: column;
            align-items: center;

            @media only screen and (max-width: 770px) {
                border-radius: 12px;
                background: linear-gradient(
                    180deg,
                    #222a35aa 0%,
                    #222a35ee 100%
                );
            }

            > h1 {
                margin: 32px auto 0 auto;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 61.04px;
                line-height: 79px;
                letter-spacing: -0.5px;

                @media only screen and (max-width: 770px) {
                    margin-top: 12px;
                    font-size: 36px;
                    line-height: 48px;
                }
            }

            .headerPillContainer {
                display: flex;
                flex-direction: row;
                margin: 32px 0;
            }

            .headerPill {
                border-radius: 6px;
                padding: 6px 24px;

                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 1.5;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.25px;
                color: var(--gray-50);

                @media only screen and (max-width: 770px) {
                    font-size: 20px;
                }
            }

            .headerMetricsContainer {
                padding-top: 32px;
                border-top: 1px solid var(--gray-70);

                width: 100%;
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;

                @media only screen and (max-width: 770px) {
                    grid-auto-flow: row;
                }

                .headerMetric {
                    display: flex;
                    flex-direction: column;
                    height: 70px;
                    text-align: center;
                    color: var(--gray-00);

                    &:not(:first-child) {
                        border-left: 1px solid var(--gray-70);
                    }
                    @media only screen and (max-width: 770px) {
                        &:not(:first-child) {
                            border-left: none;
                        }
                    }

                    .headerMetricValue {
                        font-family: 'Courier New', Courier, monospace;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 150%;
                        color: var(--green-30);
                    }

                    .headerMetricLabel {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                    }
                }
            }
        }

        .headerSubtext {
            padding: 24px 24px 36px 24px;
        }
    }
}

.regionsContainer {
    max-width: 1200px;
    margin: 32px auto 12px;
    padding: 24px;
}

.operatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 32px auto 12px;
    padding: 24px;
}

.activeOperatorText {
    font-weight: 600;
    font-size: 12.8px;
    line-height: 150%;
    letter-spacing: 0.25px;
    color: var(--gray-70);
}

.title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    line-height: 44px;
    letter-spacing: -0.25px;
    max-width: 600px;
    margin-top: 150px;
}

.subtitle {
    color: var(--gray-60);
}

.subheader {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
}

.dashboardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media only screen and (max-width: 770px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 580px) {
        grid-template-columns: 1fr;
    }
}

.dashboardListItem {
    height: 200px;
    cursor: pointer;

    padding: 20px;
    margin: 24px;
    border-radius: 6px;
    border: 1px solid var(--gray-30);
    background-color: var(--gray-00);
    margin-bottom: 8px;

    display: grid;
    grid-template-rows: auto 20px;
    justify-content: center;
    gap: 12px;

    &:hover {
        border-color: var(--blue-30);
        .organizationLogo {
            filter: unset;
        }
    }

    .organizationLogo {
        margin: auto;
        max-height: 120px;
        max-width: 120px;
        filter: grayscale(1);
        transition: filter ease-in-out 150ms;
    }

    .organizationLink {
        height: 24px;
        justify-self: center;
    }
}

.operatorLogoContainer {
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 770px) {
        flex-direction: column;
    }
}

.operatorLogo {
    margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 30px;
    max-width: 150px;
    color: var(--gray-70);

    svg {
        height: 100%;
        width: 100%;
    }
}

.infoHeader {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
}
